import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/default-layout.js";
import droneSamaGana from 'data/default.prt';
import scaleSamaGana from 'data/default.pkb';
export const notesSamaGana = [{
  white: "Sa",
  black: "re"
}, {
  white: "Re",
  black: "ga"
}, {
  white: "Ga"
}, {
  white: "ma",
  black: "Ma"
}, {
  white: "Pa",
  black: "dha"
}, {
  white: "Dha",
  black: "ni"
}, {
  white: "Ni"
}, {
  white: "SA"
}];
export const example1 = `ni' Sa Ga ma dha(G) ni dha(G) Pa(G) 2
ma Pa(G) Pa .3 ma .3 Ga 2 Ga ma Ga 2 Re Sa 4
ma' dha' ni'(G) 2 Sa Ga ma dha ni(G) 2 ni Sa" 4
ni Sa" Ga" ma" Ga" Re" Sa" 4
ni Sa" dha(G) Pa .3 ma Pa(G) 2 Pa ma Ga 2
Ga ma Ga 2 Re 2 Sa 4
dha' ni' Sa 8`;
export const example2 = `Sa(G)(ni',Re,-0.7,1.2) 2
Sa ga(G)(36,12,2.5,3.34) 2 Re Sa 2
ni' Re(G)(72,Re,7,4.35) 2 ni'(G) Sa 2
ga(G)(30,-30,6,3.3) Re 2 Re ga(G) 2 Re Sa 2
Sa ni' . Dha'(G) Pa' ma' Pa' ni'(G)(30,-30,7,3.3) 2 Dha' ni'(G) ga(G) 2 Re(G) ; Sa 2
Sa Re(G)(Re,ga,1.5,1) ga(G) ma Pa ma ga ; Re(G)(Re,45,7,3.5) 2 Sa 2`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CommonPitch = makeShortcode("CommonPitch");
const DronePlayer = makeShortcode("DronePlayer");
const ScalePlayer = makeShortcode("ScalePlayer");
const MotifPlayer = makeShortcode("MotifPlayer");
const layoutProps = {
  notesSamaGana,
  example1,
  example2,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "puretones-user-guide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#puretones-user-guide",
        "aria-label": "puretones user guide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PureTones User Guide`}</h2>
    <p>{`Here is a quick user guide to start using the PureTones App. You can also `}<a parentName="p" {...{
        "href": "/learn/"
      }}>{`learn more`}</a>{` about Indian Classical music through in-depth articles and interactive audio demonstrations at our `}<a parentName="p" {...{
        "href": "/learn/"
      }}>{`Learn`}</a>{` page.`}</p>
    <p>{`You can reach the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`PureTones App`}</a>{` at any time, by clicking on the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` link in the top navigation bar. It has four main sections:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#common-parameters"
        }}>{`Common Parameters`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#drone"
        }}>{`Drone`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#scale"
        }}>{`Scale`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#sequencer"
        }}>{`Sequencer`}</a></li>
    </ol>
    <p>{`Each of these sections is explained below.`}</p>
    <h3 {...{
      "id": "common-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#common-parameters",
        "aria-label": "common parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Common Parameters`}</h3>
    <p>{`As you may well know, in Indian Classical Music, all musical notes are defined in relation with the fundamental note `}<strong parentName="p">{`Sa`}</strong>{`. The `}<inlineCode parentName="p">{`Common Parameters`}</inlineCode>{` control the `}<inlineCode parentName="p">{`Key`}</inlineCode>{` and `}<inlineCode parentName="p">{`Offset`}</inlineCode>{` of `}<strong parentName="p">{`Sa`}</strong>{`. This enables you to select a `}<strong parentName="p">{`Sa`}</strong>{` according to your preference and comfort. You can also select it using the dialog below. You will notice that your settings persist across pages.`}</p>
    <CommonPitch mdxType="CommonPitch" />
    <h3 {...{
      "id": "drone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#drone",
        "aria-label": "drone permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Drone`}</h3>
    <p>{`On the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` page, click on the `}<inlineCode parentName="p">{`Drone`}</inlineCode>{` tab below the `}<inlineCode parentName="p">{`Common Parameters`}</inlineCode>{` section to get to the Drone section. Once in the Drone section, use the following steps to start a drone track and tune it according to your requirement and preference.`}</p>
    <ol>
      <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Start`}</inlineCode>{` under `}<inlineCode parentName="li">{`Drone Controls`}</inlineCode>{`. This starts a generic `}<strong parentName="li">{`Pa`}</strong>{`-Tanpura in the key you selected using the `}<inlineCode parentName="li">{`Common Parameters`}</inlineCode>{` above. You can click on `}<inlineCode parentName="li">{`Start`}</inlineCode>{` below to hear the generic drone track. This is the same track you would hear and tune in the Drone section.`}</li>
    </ol>
    <DronePlayer title='Generic Drone without tuning controls' settings={droneSamaGana} mdxType="DronePlayer" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change your octave using the `}<inlineCode parentName="li">{`Octave`}</inlineCode>{` selector.`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`Period`}</inlineCode>{` to control the playing tempo and `}<inlineCode parentName="li">{`Level`}</inlineCode>{` to control the volume level.`}</li>
      <li parentName="ol">{`There are six tunable strings accessible via the string tabs.`}</li>
      <li parentName="ol">{`Select which note the string should play and use the `}<inlineCode parentName="li">{`Fine Tune`}</inlineCode>{` and `}<inlineCode parentName="li">{`Ultrafine Tune`}</inlineCode>{` sliders to finetune the pitch of each string. You can also input a number directly into the input boxes, for precise data entry.`}</li>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`Variance`}</inlineCode>{` and `}<inlineCode parentName="li">{`Timbre`}</inlineCode>{` controls to fine tune the harmonic content produced by each string, and `}<inlineCode parentName="li">{`Gain`}</inlineCode>{` to adjust the relative gain levels of different strings.`}</li>
      <li parentName="ol">{`To turn a string on or off, use the `}<inlineCode parentName="li">{`Loop`}</inlineCode>{` toggle switch.`}</li>
      <li parentName="ol">{`If you are familiar with a tanpura, then you should be able to relate the adjustment of the thread under the string, to the `}<inlineCode parentName="li">{`Variance`}</inlineCode>{` and `}<inlineCode parentName="li">{`Timbre`}</inlineCode>{` controls. `}<inlineCode parentName="li">{`Variance`}</inlineCode>{` controls the speed of unraveling of the harmonics, while `}<inlineCode parentName="li">{`Timbre`}</inlineCode>{` controls the emphasis of specific harmonics which may be necessary to support specific notes in a Raga.`}</li>
      <li parentName="ol">{`When you are satisfied, you can save your tuning to a file by clicking on `}<inlineCode parentName="li">{`Save`}</inlineCode>{` which can be uploaded later using `}<inlineCode parentName="li">{`Restore`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`To start afresh, just click on `}<inlineCode parentName="li">{`Reset`}</inlineCode>{`.`}</li>
    </ol>
    <h3 {...{
      "id": "scale",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#scale",
        "aria-label": "scale permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scale`}</h3>
    <p>{`On the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` page, click on the `}<inlineCode parentName="p">{`Scale`}</inlineCode>{` tab below the `}<inlineCode parentName="p">{`Common Parameters`}</inlineCode>{` section to get to the Scale section. Once in the Scale section, use the following steps to start a 12 note musical scale and tune it according to your requirement and preference.`}</p>
    <ol>
      <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Start`}</inlineCode>{` under `}<inlineCode parentName="li">{`Scale Controls`}</inlineCode>{`. This starts a musical scale in the key you selected using the `}<inlineCode parentName="li">{`Common Parameters`}</inlineCode>{` above, as per the `}<a parentName="li" {...{
          "href": "/learn/tuningsystems-4/"
        }}>{`Venkatamakhin-Ramamatya Tuning`}</a>{`. You can click on `}<inlineCode parentName="li">{`Start`}</inlineCode>{` below to hear the generic scale. This is the same scale you would hear and tune in the Scale section.`}</li>
    </ol>

    <ScalePlayer title='Generic Scale without tuning controls' noteSpec={notesSamaGana} scale={scaleSamaGana} mdxType="ScalePlayer" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`You can play the scale using your computer's QWERTY keyboard. This can be turned on and off using the `}<inlineCode parentName="li">{`Computer Keyboard`}</inlineCode>{` toggle switch.`}</li>
      <li parentName="ol">{`The different notes of the scale can be played using the keys described below: `}<inlineCode parentName="li">{`a`}</inlineCode>{` - `}<strong parentName="li">{`Sa`}</strong>{`, `}<inlineCode parentName="li">{`w`}</inlineCode>{` - `}<strong parentName="li">{`re`}</strong>{`, `}<inlineCode parentName="li">{`s`}</inlineCode>{` - `}<strong parentName="li">{`Re`}</strong>{`, `}<inlineCode parentName="li">{`e`}</inlineCode>{` - `}<strong parentName="li">{`ga`}</strong>{`, `}<inlineCode parentName="li">{`d`}</inlineCode>{` - `}<strong parentName="li">{`Ga`}</strong>{`, `}<inlineCode parentName="li">{`f`}</inlineCode>{` - `}<strong parentName="li">{`ma`}</strong>{`, `}<inlineCode parentName="li">{`t`}</inlineCode>{` - `}<strong parentName="li">{`Ma`}</strong>{`, `}<inlineCode parentName="li">{`g`}</inlineCode>{` - `}<strong parentName="li">{`Pa`}</strong>{`, `}<inlineCode parentName="li">{`y`}</inlineCode>{` - `}<strong parentName="li">{`dha`}</strong>{`, `}<inlineCode parentName="li">{`h`}</inlineCode>{` - `}<strong parentName="li">{`Dha`}</strong>{`, `}<inlineCode parentName="li">{`u`}</inlineCode>{` - `}<strong parentName="li">{`ni`}</strong>{`, `}<inlineCode parentName="li">{`j`}</inlineCode>{` - `}<strong parentName="li">{`Ni`}</strong>{` and `}<inlineCode parentName="li">{`k`}</inlineCode>{` - `}<strong parentName="li">{`SA`}</strong>{`.`}</li>
      <li parentName="ol">{`Change your octave using the `}<inlineCode parentName="li">{`Octave`}</inlineCode>{` selector. Or use the `}<inlineCode parentName="li">{`z`}</inlineCode>{` key to shift to a lower octave and the `}<inlineCode parentName="li">{`x`}</inlineCode>{` key to shift to a higher octave.`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`Sustain`}</inlineCode>{` to control the sustain and `}<inlineCode parentName="li">{`Level`}</inlineCode>{` to control the volume level.`}</li>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`Note`}</inlineCode>{` tabs labelled as `}<inlineCode parentName="li">{`Sa`}</inlineCode>{`, `}<inlineCode parentName="li">{`re`}</inlineCode>{`, `}<inlineCode parentName="li">{`Re`}</inlineCode>{`,... to access each of the 12 notes.`}</li>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`Fine Tune`}</inlineCode>{` and `}<inlineCode parentName="li">{`Ultrafine Tune`}</inlineCode>{` sliders to finetune the pitch of each note. You can also input a number directly into the input boxes, for precise data entry.`}</li>
      <li parentName="ol">{`When you are satisfied, you can save your tuning to a file by clicking on `}<inlineCode parentName="li">{`Save`}</inlineCode>{` which can be uploaded later using `}<inlineCode parentName="li">{`Restore`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`To start afresh, just click on `}<inlineCode parentName="li">{`Reset`}</inlineCode>{`.`}</li>
    </ol>
    <h3 {...{
      "id": "sequencer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sequencer",
        "aria-label": "sequencer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sequencer`}</h3>
    <p>{`On the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` page, click on the `}<inlineCode parentName="p">{`Sequencer`}</inlineCode>{` tab below the `}<inlineCode parentName="p">{`Common Parameters`}</inlineCode>{` section to get to the Sequencer section. Once in the Sequencer section, use the following steps to start composing musical works based on the Drone and Scale settings you have tuned so far.`}</p>
    <ol>
      <li parentName="ol">{`The Sequencer section provides you with three voices which can play musical tunes simultaneously.`}</li>
      <li parentName="ol">{`You can set the Octave and Tone of each voice, using the corresponding `}<inlineCode parentName="li">{`Octave`}</inlineCode>{` and `}<inlineCode parentName="li">{`Tone`}</inlineCode>{` controls.`}</li>
      <li parentName="ol">{`There are four tones available: `}<inlineCode parentName="li">{`String 1`}</inlineCode>{`, `}<inlineCode parentName="li">{`String 2`}</inlineCode>{`, `}<inlineCode parentName="li">{`Bow`}</inlineCode>{` and `}<inlineCode parentName="li">{`Reed`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Type your musical compositions in the appropriate textboxes labelled as `}<inlineCode parentName="li">{`Composition Editor`}</inlineCode>{`. For details about the syntax and grammar for specifying musical compositions, see the Grammar documentation below. We have also provided some examples below which you can play and read the notation alongside to understand how the notation corresponds to musical notes.`}</li>
      <li parentName="ol">{`If you fine-tuned the notes of the musical scale using the controls in the Scale section, then those settings are automatically incorporated in the Sequencer. For example, if you made the `}<strong parentName="li">{`ga`}</strong>{` sharp in the Scale section, then if you type `}<inlineCode parentName="li">{`ga`}</inlineCode>{` in a composition, the Sequencer would play the sharpened note `}<strong parentName="li">{`ga`}</strong>{`.`}</li>
      <li parentName="ol">{`Similar to the other sections, you can save your compositions to a file by clicking on `}<inlineCode parentName="li">{`Save`}</inlineCode>{` which can be uploaded later using `}<inlineCode parentName="li">{`Restore`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`To start afresh, just click on `}<inlineCode parentName="li">{`Reset`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`You are at liberty to use the Sequencer to create musical works and use them as you like. We would be grateful if you acknowledge and credit PureTones by linking to this website.`}</li>
    </ol>
    <h3 {...{
      "id": "musical-grammar-of-the-puretones-sequencer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#musical-grammar-of-the-puretones-sequencer",
        "aria-label": "musical grammar of the puretones sequencer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Musical Grammar of the PureTones Sequencer`}</h3>
    <p>{`PureTones Sequencer uses a simple grammar to write compositions using notation from Indian Classical music as specified below:`}</p>
    <pre><code parentName="pre" {...{}}>{`
    <motif> = <note> + ' ' + <motif>
    <note> = <identifier> + ' ' + <timing>
    <identifier> = <name> + <octave> + <shake> + <stroke>
    <name> = Sa | re | Re | ga | Ga | ma | Ma | Pa | dha | Dha | ni | Ni
    <octave> = null | " | '
    <shake> = null | '(G)' | '(G)(<start>,<end>,<rate>,<times>)'
    <stroke> = null | ^
    <timing> = null | <jati> + <repeats>
    <jati> = null | . | ; | ,
    <repeats> = null | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
    <start> = <name> + <octave> | number
    <end> = <name> + <octave> | number
    <rate> = number
    <times> = number

`}</code></pre>
    <ol>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`motif`}</inlineCode>{` refers to a musical composition. It is a sequence of `}<inlineCode parentName="li">{`note`}</inlineCode>{`'s separated by whitespaces.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`note`}</inlineCode>{` is a pair of an `}<inlineCode parentName="li">{`identifier`}</inlineCode>{` and a `}<inlineCode parentName="li">{`timing`}</inlineCode>{` separated by whitespace.`}</li>
      <li parentName="ol">{`An `}<inlineCode parentName="li">{`identifier`}</inlineCode>{` is a triplet of `}<inlineCode parentName="li">{`name`}</inlineCode>{`, `}<inlineCode parentName="li">{`octave`}</inlineCode>{` and `}<inlineCode parentName="li">{`shake`}</inlineCode>{` without any separators.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`name`}</inlineCode>{` could be any one of 12 notes from an octave denoted as follows `}<inlineCode parentName="li">{`Sa re Re ga Ga ma Ma Pa dha Dha ni Ni`}</inlineCode>{` where notes beginning with a lowercase are `}<em parentName="li">{`Komal`}</em>{` (flat) notes and those beginning with an uppercase are `}<em parentName="li">{`Tivra`}</em>{` (sharp) notes. Of course, `}<inlineCode parentName="li">{`Sa`}</inlineCode>{` and `}<inlineCode parentName="li">{`Pa`}</inlineCode>{` are always capitalized.`}</li>
      <li parentName="ol">{`An `}<inlineCode parentName="li">{`octave`}</inlineCode>{` specification is optional. There are three `}<em parentName="li">{`Saptaks`}</em>{` (octaves):  `}<em parentName="li">{`Mandra`}</em>{` (low), `}<em parentName="li">{`Madhya`}</em>{` (middle) and `}<em parentName="li">{`Tara`}</em>{` (high). A `}<inlineCode parentName="li">{`"`}</inlineCode>{` symbol denotes `}<em parentName="li">{`Tara Saptak`}</em>{`, a `}<inlineCode parentName="li">{`'`}</inlineCode>{` symbol denotes `}<em parentName="li">{`Mandra Saptak`}</em>{` and no octave specification means `}<em parentName="li">{`Madhya Saptak`}</em>{`.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`shake`}</inlineCode>{` denotes whether or not the note is shaken or rendered with a `}<em parentName="li">{`Gamaka`}</em>{`. A `}<inlineCode parentName="li">{`shake`}</inlineCode>{` specification is optional. It is denoted by `}<inlineCode parentName="li">{`(G)`}</inlineCode>{` to mean a note with `}<em parentName="li">{`Gamaka`}</em>{` or not specified to mean an unmodulated note. It can also be specified with additional parameters like `}<inlineCode parentName="li">{`(G)(start,end,rate,times)`}</inlineCode>{` where `}<inlineCode parentName="li">{`start`}</inlineCode>{` is the starting pitch, `}<inlineCode parentName="li">{`end`}</inlineCode>{` is the ending pitch in cents, `}<inlineCode parentName="li">{`rate`}</inlineCode>{` is the rate of the shake, and `}<inlineCode parentName="li">{`times`}</inlineCode>{` is the number of times for which the shake is to be repeated (including fractional numbers).`}</li>
      <li parentName="ol">{`The pitches `}<inlineCode parentName="li">{`start`}</inlineCode>{` and `}<inlineCode parentName="li">{`end`}</inlineCode>{` can be specified either as notes identified by a `}<inlineCode parentName="li">{`name`}</inlineCode>{` and an optional `}<inlineCode parentName="li">{`octave`}</inlineCode>{` specification, or in terms of cents with respect to the pitch of the current note. For example, `}<inlineCode parentName="li">{`ga(G)(36,12,2.5,3.34)`}</inlineCode>{` specifies a `}<inlineCode parentName="li">{`shake`}</inlineCode>{` which oscillates between 36 cents above `}<strong parentName="li">{`ga`}</strong>{` and 12 cents above `}<strong parentName="li">{`ga`}</strong>{`. Another example is `}<inlineCode parentName="li">{`Re(G)(Re,ga,1.5,1)`}</inlineCode>{` which specifies a `}<inlineCode parentName="li">{`shake`}</inlineCode>{` which oscillates between `}<strong parentName="li">{`Re`}</strong>{` and `}<strong parentName="li">{`ga`}</strong>{`.`}</li>
      <li parentName="ol">{`The parameters `}<inlineCode parentName="li">{`rate`}</inlineCode>{` and `}<inlineCode parentName="li">{`times`}</inlineCode>{` are specified as a `}<inlineCode parentName="li">{`number`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`stroke`}</inlineCode>{` refers to whether the next note is played with a separate stroke or not. A `}<inlineCode parentName="li">{`^`}</inlineCode>{` symbol denotes that the next note would be played using the sustain from the stroke of the current note. No stroke specification means that the next note would be played using a separate new stroke.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`timing`}</inlineCode>{` is a pair of `}<inlineCode parentName="li">{`jati`}</inlineCode>{` and `}<inlineCode parentName="li">{`repeats`}</inlineCode>{` without any separators. It is an optional specification. If a `}<inlineCode parentName="li">{`timing`}</inlineCode>{` specification is not provided, it means the `}<inlineCode parentName="li">{`note`}</inlineCode>{` specified in the previous token is a whole note (or has a duration of 1 period).`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`jati`}</inlineCode>{` denotes a subdivision of a period. It is an optional specification. A `}<inlineCode parentName="li">{`.`}</inlineCode>{` symbol denotes a `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\frac{1}{2}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` note, a `}<inlineCode parentName="li">{`;`}</inlineCode>{` symbol denotes a `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`4`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\frac{1}{4}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` note and a `}<inlineCode parentName="li">{`,`}</inlineCode>{` symbol a `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`8`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\frac{1}{8}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`8`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` note. If `}<inlineCode parentName="li">{`jati`}</inlineCode>{` is omitted, it denotes a whole note.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`repeats`}</inlineCode>{` specification determines the factor by which a note duration derived from its `}<inlineCode parentName="li">{`jati`}</inlineCode>{` is extended. It is an optional specification. It can take any numerical value from `}<inlineCode parentName="li">{`1`}</inlineCode>{` to `}<inlineCode parentName="li">{`8`}</inlineCode>{`. If a `}<inlineCode parentName="li">{`repeats`}</inlineCode>{` specification is omitted, it means a default value of `}<inlineCode parentName="li">{`1`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`As an example, a `}<inlineCode parentName="li">{`timing`}</inlineCode>{` specification of `}<inlineCode parentName="li">{`.3`}</inlineCode>{` denotes a note of duration `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`3`}</mn><mo parentName="mrow">{`×`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`3\\times\\frac{1}{2} = 1\\frac{1}{2}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7278em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`3`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`×`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` notes, since the `}<inlineCode parentName="li">{`jati`}</inlineCode>{` is `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\frac{1}{2}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` note and the `}<inlineCode parentName="li">{`repeats`}</inlineCode>{` is 3. As another example, a `}<inlineCode parentName="li">{`timing`}</inlineCode>{`  specification of `}<inlineCode parentName="li">{`2`}</inlineCode>{` denotes a note of duration 2 whole notes, and a `}<inlineCode parentName="li">{`timing`}</inlineCode>{` specification of `}<inlineCode parentName="li">{`;`}</inlineCode>{` denotes a note of duration of a `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`4`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\frac{1}{4}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1.1901em",
                    "verticalAlign": "-0.345em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mopen nulldelimiter"
                  }}></span><span parentName="span" {...{
                    "className": "mfrac"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8451em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.655em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.23em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "frac-line",
                              "style": {
                                "borderBottomWidth": "0.04em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.394em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.345em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "mclose nulldelimiter"
                  }}></span></span></span></span></span></span>{` note.`}</li>
    </ol>
    <h3 {...{
      "id": "example-compositions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-compositions",
        "aria-label": "example compositions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Compositions`}</h3>
    <p>{`Here is an example composition using the grammar specified above. Click on `}<inlineCode parentName="p">{`Start`}</inlineCode>{` below. The composition will start playing and the text of the composition will be displayed. You can try to follow the music and correlate it with the text.`}</p>

    <MotifPlayer title='Example 1' motif={example1} scale={scaleSamaGana} mdxType="MotifPlayer" />
    <p>{`Here is another example showing the use of the full specification for a gamaka using the syntax of `}<inlineCode parentName="p">{`(G)(start,end,rate,times)`}</inlineCode>{`. Again, click on `}<inlineCode parentName="p">{`Start`}</inlineCode>{` and try to relate the music to the text.`}</p>

    <MotifPlayer title='Example 2' motif={example2} scale={scaleSamaGana} mdxType="MotifPlayer" />
    <p>{`You can also scroll up, play the drone and come back and play these example compositions along with the drone. This may provide you with another perspective on how the drone and composition tracks interact musically.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      